export default {
  firmware_version_string_to_array: function (version: string) {
    return version.replace(/[^\d|\.]/gi, '').split('.').map(v => Number(v));
  },
  firmware_version_greater_than_or_equal_to: function (source: string, target: string) {
    if (source === target) return true // equal
    if (typeof source != 'string' || typeof target != 'string') return

    const sourceVersion = this.firmware_version_string_to_array(source)
    const targetVersion = this.firmware_version_string_to_array(target)

    const maxLength = sourceVersion.length > targetVersion.length ? sourceVersion.length : targetVersion.length
    for (let i = 0; i < maxLength; i++) {
      const versionS = sourceVersion[i] || 0
      const versionT = targetVersion[i] || 0

      if (versionS > versionT) return true
      if (versionS < versionT) return false
    }
    return true // equal
  },
};




import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class dealer {
  constructor(private ims: Imports) {}

  public partners$w(): Observable<any> {
    return this.ims.dealerService.partners$w;
  }

  public dealer_info$w(): Observable<any> {
    return this.ims.dealerService.dealer_info$w;
  }

  public dealer_stats$w(): Observable<any> {
    return this.ims.dealerService.dealer_stats$w;
  }
  
  public dealer_stats_by_dealer$w(): Observable<any> {
    return this.ims.dealerService.dealer_stats_by_dealer$w;
  }

  public dealer_stats_by_dealers$w(): Observable<any> {
    return this.ims.dealerService.dealer_stats_by_dealers$w;
  }

  public dealer_stats_by_sites$w(): Observable<any> {
    return this.ims.dealerService.dealer_stats_by_sites$w;
  }

  public dealer_stats_by_devices$w(): Observable<any> {
    return this.ims.dealerService.dealer_stats_by_devices$w;
  }

  // ----------------------

  public async get_site_partners(): Promise<Array<any>> | undefined {
    await this.load_site_partners();
    return this.ims.dealerService.partners$s.getValue();
  }

  public async get_dealer_info(isForce?: boolean): Promise<Array<any>> | undefined {
    if(!this.ims.dealerService.dealer_info$s.getValue() || isForce) await this.load_dealer_info();
    return this.ims.dealerService.dealer_info$s.getValue();
  }

  public async get_dealer_stats(startDate, endDate, sub_dealer_id?, period_type?, types?, rank?): Promise<any> | undefined {
    await this.load_dealer_stats(startDate, endDate, sub_dealer_id, period_type, types, rank);
    return this.ims.dealerService.dealer_stats$s.getValue();
  }

  public async get_dealer_stats_by_dealer(startDate, endDate, sub_dealer_id?, period_type?, types?, rank?): Promise<any> | undefined {
    await this.load_dealer_stats_by_dealer(startDate, endDate, sub_dealer_id, period_type, types, rank);
    return this.ims.dealerService.dealer_stats_by_dealer$s.getValue();
  }

  public async get_dealer_stats_by_dealers(startDate, endDate, sub_dealer_ids?, period_type?, types?, rank?): Promise<any> | undefined {
    await this.load_dealer_stats_by_dealers(startDate, endDate, sub_dealer_ids, period_type, types, rank);
    return this.ims.dealerService.dealer_stats_by_dealers$s.getValue();
  }

  public async get_dealer_stats_by_sites(startDate, endDate, site_ids?, period_type?, types?, rank?): Promise<any> | undefined {
    await this.load_dealer_stats_by_sites(startDate, endDate, site_ids, period_type, types, rank);
    return this.ims.dealerService.dealer_stats_by_sites$s.getValue();
  }

  public async get_dealer_stats_by_devices(startDate, endDate, device_ids?, period_type?, types?, rank?): Promise<any> | undefined {
    await this.load_dealer_stats_by_devices(startDate, endDate, device_ids, period_type, types, rank);
    return this.ims.dealerService.dealer_stats_by_devices$s.getValue();
  }

  // -------------------

  public async load_site_partners(): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerService.getSitePartners(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Partners" });
      return false;
    }
  }

  public async load_dealer_info(): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerService.getDealerInfo(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Partners" });
      return false;
    }
  }

  public async update_dealer_info(data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerService.updateDealerInfo(dealer_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Division" });
      return false;
    }
  }

  // STATISTICS
  public async load_dealer_stats(startDate, endDate, sub_dealer_id?, period_type?, types?, rank?): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerService.getDealerStatistics(dealer_id, startDate, endDate,sub_dealer_id, period_type, types, rank).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Partners" });
      return false;
    }
  }

  public async load_dealer_stats_by_dealer(startDate, endDate, sub_dealer_id?, period_type?, types?, rank?): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerService.getStatisticsByDealer(dealer_id, startDate, endDate,sub_dealer_id, period_type, types, rank).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Partners" });
      return false;
    }
  }

  public async load_dealer_stats_by_dealers(startDate, endDate, sub_dealer_ids?, period_type?, types?, rank?): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerService.getStatisticsByDealers(dealer_id, startDate, endDate,sub_dealer_ids, period_type, types, rank).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Partners" });
      return false;
    }
  }

  public async load_dealer_stats_by_sites(startDate, endDate, site_ids?, period_type?, types?, rank?): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerService.getStatisticsBySites(dealer_id, startDate, endDate,site_ids, period_type, types, rank).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Partners" });
      return false;
    }
  }

  public async load_dealer_stats_by_devices(startDate, endDate, device_ids?, period_type?, types?, rank?): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerService.getStatisticsByDevices(dealer_id, startDate, endDate,device_ids, period_type, types, rank).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Partners" });
      return false;
    }
  }

  // ----------------------

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}

import { Injectable, EventEmitter } from '@angular/core';
import { Modal } from '../model/modal';
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import moment from "moment-timezone";

@Injectable()
export class CommonService {
  m_warning: Modal = new Modal();
  isLoading = false;

  isMobile = false;
  isHide= false;

  isPageLoading: EventEmitter<boolean> = new EventEmitter();
  siteTab: EventEmitter<boolean> = new EventEmitter();
  updateSite = new BehaviorSubject<any>(null);
  targetMoveDevice = null;
  targetMoveCategory = null;
  targetMoveChannel = null;
  targetNoteId = null; // for service note / mp direction
  targetUrl = null; // for service note / mp direction
  public updateSite$ = this.updateSite.asObservable();

  // Bridge
  bridgeHWversionOffset = {
    'CKB304' : 0,
    'CKB308' : 7,
    'CKB312' : 3,
    'CKB416' : 16,
  }

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService) {
  }

  emitPageLoading(data) {
    this.isPageLoading.emit(data);
  }
  getPageLoading() {
    return this.isPageLoading;
  }

  emitSiteTab(data) {
    if (data[0]) {
      if (data[0].targetDevice) {
        this.targetMoveDevice = data[0].targetDevice;
      } else {
        this.targetMoveDevice = data[0].targetDevice;
      }
      if (data[0].targetCategory) {
        this.targetMoveCategory = data[0].targetCategory;
      }
      this.targetMoveChannel = data[0].targetChannel;
      this.targetNoteId = data[0].targetNoteId;
      this.targetUrl = data[0].targetUrl;
    }
    this.siteTab.emit(data);
  }
  getSiteTab() {
    return this.siteTab;
  }

  emitUpdateSite(data) {
    this.updateSite.next(data);
  }
  getUpdateSite() {
    return this.updateSite$;
  }

  datetimeSimple(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const today = new Date().toDateString();
    const input = new Date(timestamp).toDateString();

    if (today === input) {
      return moment(timestamp).format('hh:mm');
    }

    return moment(timestamp).format('MMM D');
  }

  extractDateTime(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const date = moment(timestamp).format('ddd D MMMM, YYYY  hh:mm a');
    return date;
  }

  extractSimpleDateTime(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const date = moment(timestamp).format('D MMM YYYY  hh:mm a');
    return date;
  }

  extractUnixDateTime(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const date = moment.unix(timestamp).format('MM/DD/YYYY  HH:mm:ss');
    return date;
  }

  extractDateTimeToUsFormat(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const date = moment(timestamp).format('MM/DD/YYYY  HH:mm:ss');
    return date;
  }

  extractDate(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const date = moment(timestamp).format('ddd D MMMM, YYYY');
    return date;
  }

  extractReceiptDate(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const date = moment(timestamp).format('DD MMM YYYY');
    return date;
  }

  extractTime(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const date = moment(timestamp).format('hh:mm a');
    return date;
  }

  extractShortDate(targetTime) {
    if (!targetTime) {
      return 'No Data';
    }
    const date = moment(targetTime).format('MMM DD, YYYY');
    return date;
  }

  periodDate(beginTime, endTime) {
    if (!beginTime) {
      return 'No Data';
    }
    let date = '';
    const beginDate = moment(beginTime.split('T')[0]).format('MMM, YYYY');
    const endDate = moment(endTime.split('T')[0]).format('MMM, YYYY');

    if (beginDate === endDate) {
      date = beginDate;
    } else {
      date = beginDate + ' - ' + endDate;
    }
    return date;
  }

  periodBillDate(beginTime, endTime) {
    if (!beginTime) {
      return 'No Data';
    }
    let date = '';
    const beginDate = moment(beginTime.split('T')[0]).format('MMMM, YYYY');
    const endDate = moment(endTime.split('T')[0]).format('MMMM, YYYY');

    if (beginDate === endDate) {
      const beginDateSub = moment(beginTime.split('T')[0]).format('MMMM DD');
      const endDateSub = moment(endTime.split('T')[0]).format('MMMM DD, YYYY');
      date = beginDateSub + ' to ' + endDateSub;
    } else {
      date = beginDate + ' to ' + endDate;
    }
    return date;
  }

  periodReceiptDate(beginTime, endTime) {
    if (!beginTime) {
      return 'No Data';
    }
    let date = '';
    const beginDate = moment(beginTime.split('T')[0]).format('MMM, YYYY');
    const endDate = moment(endTime.split('T')[0]).format('MMM, YYYY');

    if (beginDate === endDate) {
      const beginDateSub = moment(beginTime.split('T')[0]).format('MMM DD');
      const endDateSub = moment(endTime.split('T')[0]).format('MMM DD, YYYY');
      date = beginDateSub + ' - ' + endDateSub;
    } else {
      date = beginDate + ' - ' + endDate;
    }
    return date;
  }

  summaryDate(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const date = moment(timestamp).format('MMMM DD, YYYY');
    return date;
  }

  statusDate(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    const date = moment(parseInt(timestamp)*1000).format('M/D/YYYY HH:mm');
    return date;
  }

  getAccessTime(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    let date = moment.unix(timestamp).format('MM/DD/YY hh:mm:ss a');
    const timeString = `${date}`;
    return timeString;
  }

  getGeneralTime(timestamp) {
    if (!timestamp) {
      return 'No Data';
    }
    let date = moment(timestamp).format('MM/DD/YY h:mm:ss a');
    const timeString = `${date}`;
    return timeString;
  }


  getIntuitiveTimeString(timeStr: string) {
    let now = new Date();
    let tm = new Date(timeStr);

    let temp = now.getTime() - tm.getTime();
    let timeDifference = temp / 60000;
    let itIs: string = "";
    if (timeDifference < 1) itIs = "a few secs ago";
    else if (timeDifference < 2) itIs = "a min ago";
    else if (timeDifference < 60) itIs = Math.floor(timeDifference) + " mins ago";
    else if (timeDifference < 120) itIs = "an hour ago";
    else if (timeDifference < 2880) itIs = Math.floor(timeDifference / 60) + " hours ago";
    else itIs = Math.floor(timeDifference / 60 / 24) + " days ago";
    return itIs;
  }

  getIntuitiveTimeStamp(timestamp) {
    let now = new Date();
    let tm = timestamp;
    let temp = now.getTime() - tm * 1000;
    let timeDifference = temp / 60000;
    let itIs: string = "";
    if (timeDifference < 1) itIs = "a few secs ago";
    else if (timeDifference < 2) itIs = "a min ago";
    else if (timeDifference < 60) itIs = Math.floor(timeDifference) + " mins ago";
    else if (timeDifference < 120) itIs = "an hour ago";
    else if (timeDifference < 2880) itIs = Math.floor(timeDifference / 60) + " hours ago";
    else itIs = Math.floor(timeDifference / 60 / 24) + " days ago";
    if (!timestamp) {
      itIs = "No info";
    }
    return itIs;
  }

  getDeviceIcon(type: number) {
    switch (type) {
      case 10: // CHeKT Device
        return "videocam";
      case 11: // CHeKT Bridge
        return "device_hub";
      case 12: // non CHeKT Device
        return "videocam";
      default:
        return "devices_other";
    }
  }

  private validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  checkEmail(email: string) {
    let emailPattern: string = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$";
    if (email) {
      if (this.validateEmail(email)) {
        return true;
      }
      else {
        return false;
      }
    }

    return true;
  }

  checkURL(url: string) {
    var re = /^((?:http|https|tcp)?:\/\/)+[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    ///^((?:http|https|tcp)?:\/\/)+[\w.-]+(?:\.[\w\.-]+)+(?:\:[0-9]{2,5})+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return re.test(url);
  }

  getTimeDiff(diffTime: number): string {
    if (Number.isNaN(diffTime)) {
      return '';
    }
    if (diffTime < 0) {
      return 'Invalid time setup';
    }
    let origin_diffTime = diffTime;
    if (diffTime < 60) {
      // sec
      let str = '';
      if (diffTime == 1) {
        return '1 sec ago';
      }
      return Math.floor(diffTime) + ' secs ago';
    }
    else if ((diffTime /= 60) < 60) { // min
      return Math.floor(diffTime) + ' minutes ago';
    }
    else if ((diffTime /= 60) < 24) { // hour
      return Math.floor(diffTime) + ' hours ago';
    }
    else if ((diffTime /= 24) < 30) { // days
      return Math.floor(diffTime) + ' days ago';
    }
    else if ((diffTime /= 30) < 12) { // month
      // day
      return Math.floor(diffTime) + ' months ago';
    }
    else { // years
      return Math.floor(diffTime) + ' years ago';
    }
  }

  parseTimeGap(diffTime: number): string {
    let timeStr = '';
    if (Number.isNaN(diffTime)) {
      return '';
    }
    if (diffTime < 0) {
      return 'Invalid time setup';
    }
    let origin_diffTime = diffTime;

    if (diffTime % 60 > 0) {
      // sec
      const sec = diffTime % 60;
      let str = '';
      if (sec == 1) {
        timeStr = '1 sec';
      }
      timeStr = Math.floor(sec) + ' secs';
    }
    if ((diffTime /= 60) < 60) { // min
      if (Math.floor(diffTime) !== 0) timeStr = `${Math.floor(diffTime)} minutes ` + timeStr;

    }
    if ((diffTime /= 60) < 24) { // hour
      if (Math.floor(diffTime) !== 0) timeStr = `${Math.floor(diffTime)} hours `  + timeStr;
    }
    if ((diffTime /= 24) < 30) { // days
      if (Math.floor(diffTime) !== 0) timeStr = `${Math.floor(diffTime)} days `  + timeStr;
    }
    if ((diffTime /= 30) < 12) { // month
      // day
      if (Math.floor(diffTime) !== 0) timeStr = `${Math.floor(diffTime)} months `  + timeStr;
    }
    else { // years
      if (Math.floor(diffTime) !== 0) timeStr = `${Math.floor(diffTime)} years `  + timeStr;
    }

    return timeStr;
  }

  confirmDialog(header= '', msg= '', icon= 'done', color= 'green') {
    this.m_warning.data = {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: 'OK',
      submit_class: ['button-primary'],
      icon : icon,
      isConfirm : true,
      color: color

    };

    this.m_warning.data['submit_func'] = () => {
      if (color==='green') {
      }
    };
    this.m_warning.open();
  }

  errorDialog(err , title) {
    let msg = 'failed.';
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.confirmDialog(title, msg, 'warning', 'orange');
    }, 200);
  }

  getFullName(data: any) {
    if(!data.email)
      return 'No Name';

    if(!data.firstname && !data.lastname) {
      return data.email.slice(0, data.email.indexOf('@')) + ' (Virtual)';
    }

    let fullname = '';
    if(data.firstname) fullname += data.firstname + ' ';
    if(data.lastname) fullname += data.lastname;
    return fullname;
  }

  getQueryParams(qs) {
    if (!qs) {
      return {};
    }
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }

  async getAudioCodecFromMP4(blob) {
    let enc = new TextDecoder('utf-8')
    let audioCodec = null
    let arrBuffer = await new Response(blob).arrayBuffer()
    let byteArray = new Int8Array(arrBuffer)
    let items1 = enc.decode(byteArray).trim().split('moov')
    if (items1.length < 2) return null
    let tracks = items1[1].split('trak')
    tracks = tracks.slice(1) // 0 index is not track.
    if (tracks.length < 2) return null
    tracks.forEach(track => {
      let items2 = track.split('stsd')
      if (items2.length === 2) {
        let stsd = items2[1]
        let codec = stsd.slice(12, 16)
        if (codec === 'mp4a' || codec === 'mpga' || codec === 'samr' || codec === 'sawb') {
          audioCodec = codec
        }
      }
    })
    return audioCodec
  }

  getRemainTimeStamp(timestamp) {
    let tm = timestamp;
    let timeDifference = tm / 60;
    let itIs: string = "";
    if (timeDifference < 1) itIs = tm + " secs";
    else if (timeDifference < 2) itIs = "a min";
    else if (timeDifference < 60) itIs = Math.floor(timeDifference) + " mins";
    else if (timeDifference < 120) itIs = "an hour";
    else if (timeDifference < 2880) itIs = Math.floor(timeDifference / 60) + " hours";
    else itIs = Math.floor(timeDifference / 60 / 24) + " days";
    if (!timestamp) {
      itIs = 'No info';
    }
    return itIs;
  }

  getJSONCountryList(): Observable<any> {
    return this.http.get("../../assets/json/iso3166_1.json");
  }

  base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  zeroFill(number, width) {
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
    }
    return number + ""; // always return a string
  }

  capitalizeFirstLetter(s) {
    return (s && s[0].toUpperCase() + s.slice(1)) || "";
  }


  // TOAST
  showSuccessToast(title, msg) {
    this.toastrService.success(msg, title, { closeButton: true, timeOut: 5000, progressBar: true, enableHtml: true })
  }
  showWarningToast(title, msg) {
    this.toastrService.warning(msg, title, { closeButton: true, timeOut: 5000, progressBar: true, enableHtml: true })
  }
  showErrorToast(err, title, msg= 'failed', force = false) {
    if (err.error && !force) {
      if (err.error.permission_name) {
        title = err.error.permission_name;
      }
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    this.toastrService.error(msg, title, { closeButton: true, timeOut: 5000, progressBar: true, enableHtml: true })
  }

  // TOOLTIP
  setTooltipPosition(e, position) {
    e.stopPropagation();
    let targetEl = e.currentTarget
    if (!targetEl) return console.log('Can not found target element.')
    let targetRect = targetEl.getBoundingClientRect()

    let tooltipEl = targetEl.querySelector('.--g-tooltip')
    if (!tooltipEl) return console.log('Can not found tooltip element.')
    let tooltipRect = tooltipEl.getBoundingClientRect()

    // Position
    if (position === 'top-center') {
      tooltipEl.style.left = targetRect.left - (tooltipRect.width/2) + (targetRect.width/2) + 'px'
      tooltipEl.style.top = targetRect.top - tooltipRect.height - 5 + 'px'
    }
    else if (position === 'top-left') {
      tooltipEl.style.left = targetRect.left - tooltipRect.width + (targetRect.width/2) + 'px'
      tooltipEl.style.top = targetRect.top - tooltipRect.height - 5 + 'px'
    }
    else if (position === 'bottom-center') {
      tooltipEl.style.left = targetRect.left - (tooltipRect.width/2) + (targetRect.width/2) + 'px'
      tooltipEl.style.top = targetRect.bottom + 5 + 'px'
    }
    else if (position === 'left-center') {
      tooltipEl.style.left = targetRect.left - tooltipRect.width - 5 + 'px'
      tooltipEl.style.top = targetRect.top - (tooltipRect.height/2) + (targetRect.height/2) + 'px'
    }
    else if (position === 'right-center') {
      tooltipEl.style.left = targetRect.left + targetRect.width + 5 + 'px'
      tooltipEl.style.top = targetRect.top - (tooltipRect.height/2) + (targetRect.height/2) + 'px'
    }
    // default (top-center)
    else {
      tooltipEl.style.left = targetRect.left - (tooltipRect.width/2) + (targetRect.width/2) + 'px'
      tooltipEl.style.top = targetRect.top - tooltipRect.height - 5 + 'px'
    }
  }
}

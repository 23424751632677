import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InvoicesService } from '../services/invoices.service';
import { UsersService } from '../services/users.service';
import { CommonService } from '../services/common.service';
import { DealerService } from '../services/dealer.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { c_components } from '../../3ui/2components'

import { Helper } from '../../4services/2helper';
import moment from "moment-timezone";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.pug',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  button_list = [
    {'icon': 'home', 'link': '/dashboard', 'name': 'Dashboard', 'badge':0},
    {'icon': 'people', 'link': '/customers', 'name': 'Customers', 'badge':0},
    {'icon': 'assessment', 'link': '/reports', 'name': 'Reports', 'badge':0},
    {'icon': 'settings', 'link': '/settings', 'name': 'Settings', 'badge':0},
  ];

  me: any;
  subscription: any;

  isInit = false;
  isSmall = false;

  constructor(
    private helper: Helper,
    private router: Router,
    private invoicesService: InvoicesService,
    private usersService: UsersService,
    public commonService: CommonService,
    private dealerService: DealerService,
    private deviceDetectorService: DeviceDetectorService,
    private c_components: c_components,
    ) { }

  ngOnInit() {
    const url = window.location.href;
    const params = this.commonService.getQueryParams(url.split('?')[1]);
    let dealer_id = null;
    if (params['dealer_id']) {
      dealer_id = params['dealer_id'];
    }
    this.usersService.getMe(dealer_id).subscribe(res => {
      this.me = res;
      this.checkWCS();
      this.checkNotice();
      this.checkRegion()
    });

    if (this.deviceDetectorService.isMobile()) {
      this.isSmall = true;
      this.commonService.isMobile = true;
      this.commonService.isHide = true;
    }

    this.subscription = this.usersService.getMeChangeEmitter()
      .subscribe(me => {
        this.me = me;
      });
  }

  onResize(event) {
    if (window.innerWidth < 930) {
      this.isSmall = true;
      this.commonService.isHide = true;
    } else {
      this.isSmall = false;
      this.commonService.isHide = false;
    }
  }

  toggleSubMenu() {
    this.commonService.isHide = !this.commonService.isHide;
  }

  checkWCS() {
    if (this.isInit) {
      return;
    }
    const WCSMenu = {'icon': 'business', 'link': '/wcs-sub-dealers', 'name': 'WCS Sub Dealers', 'badge': 0};
    if (this.me.type === 2) {
      this.button_list.splice( 1, 0, WCSMenu );
    }
  }

  checkNotice() {
    if (this.isInit) return 
    if (this.me.is_division_user) return
    if (this.me.type === 3) return
    const [stime, etime] = this.setYearlyFilter() 
    this.invoicesService.getFilterV3(this.me.dealer_id, stime, etime).subscribe(res => {
      let notice = false;
      if (res.filter(invoice => invoice.status === 4).length != 0) {
        notice = true;
      }
      if (notice) {
        const noticeBtn = this.button_list.find(btn => btn.name === 'Settings');
        noticeBtn.badge += 1;
      }
    });
  }

  setYearlyFilter() {
    let beginYear = 0;
    let endYear = 0;
    beginYear = moment().subtract(12,'months').utc().startOf('month').unix();
    endYear = moment().utc().unix();
    return [beginYear, endYear];
  }

  async checkRegion(){
    if(this.isInit) return

    const CHeKTStore = {'icon': 'local_mall', 'link': '/store', 'name': 'CHeKT Store', 'badge':0}
    
    const dealerId = this.me.dealer_id
    const dealerData = (await this.dealerService.getDealerInfo(dealerId).toPromise())[0] ?? {}

    if(this.checkIsShowStoreMenu(dealerData)) {
      this.me.type === 2 ? this.button_list.splice( 4, 0, CHeKTStore ) : this.button_list.splice( 3, 0, CHeKTStore )
    }
    this.isInit = true;
  }

  checkIsShowStoreMenu(dealerData){
    // - 1: US, Canada - 2: APAC - 4: UK - 8: France - 11: EU
    const dealerRegion = dealerData?.region_id ?? 1
    if(
      dealerRegion === 1 ||
      dealerRegion === 2 ||
      dealerRegion === 4 ||
      dealerRegion === 5 ||
      dealerRegion === 8 ||
      dealerRegion === 11
    ) return true
    return false
  }

  moveToPage(btn: any) {
    btn.badge = 0;
    this.usersService.getMe().subscribe(res => {
      if(this.accessOnlyPaymentPage()) return this.openDialogAlertLimitedAccess()

      if(btn.name === 'CHeKT Store') return this.moveToCHeKTStore()
      this.helper.router.navigate_to(btn.link);
      if (this.router.url.search(btn.link) < 0) {
        this.commonService.isLoading = true;
      }
    });
  }

  accessOnlyPaymentPage(){
    const me = this.usersService.me
    const hasAccessOnlyPaymentPage = !me?.app_access_permissions?.has_access_dealer_app && me?.app_access_permissions?.has_access_payment_page
    return hasAccessOnlyPaymentPage
  }

  moveToCHeKTStore(){
    window.open('http://www.chekt.com/store', '_blank');
  }

  checkPath(path: string) {
    return window.location.pathname.split('/')[1].search(path.substring(1)) > -1;
  }

  openDialogAlertLimitedAccess() {
    this.c_components.dialog.open("warning", {
      header: 'Limited Access - "Soft Block"',
      contents: `
        <p style="text-align: left">
        Notice: Due to a past-due status on your account, access to your CHeKT Dealer Portal has been limited to the payment and invoice section. Please process payment on all open invoices and then contact us at support@chekt.com to request full access to your account.
        <br/><br/>
        Important Note:  This "soft block" status does not impact the services of your customer sites. If the past due status is not resolved within 30 days, your account will be moved to a "Service Interruption" status, and all CHeKT services will be blocked for each customer account. 
        <br/><br/>
        Thank you for your attention to this matter.
        </p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: "warning",
      isConfirm: true,
      color: "orange",
      submit_func: () => {
        if(this.me.type === 3) {
          this.router.navigate(['/settings/retail-statistics']);
        } else {
          this.router.navigate(['/settings/payment-preferences']);
        }
        return
      },
    });
  }
}

import { NgModule } from "@angular/core";
import { c_dialog } from "./c_dialog";

import { c_dialog_basic_module } from "./basic/c_dialog_basic_module";
import { c_dialog_dealer_role_module } from "./dealer_role/c_dialog_dealer_role_module";

import { c_dialog_dealer_division_module } from "./dealer_division/c_dialog_dealer_division_module";
import { c_dialog_dealer_division_add_users_module } from "./dealer_division/add_users/c_dialog_dealer_division_add_users_module";
import { c_dialog_dealer_division_add_sites_module } from "./dealer_division/add_sites/c_dialog_dealer_division_add_sites_module";
import { c_dialog_dealer_division_clear_module } from "./dealer_division/clear_division/c_dialog_dealer_division_clear_module";
import { c_dialog_dealer_payment_sepa_module } from "./dealer_payment/sepa/c_dialog_dealer_payment_sepa_module";

import { c_dialog_dealer_member_add_user_module } from "./dealer_member/c_dialog_dealer_member_add_user_module";
import { c_dialog_event_storage_region_module } from "./event-storage-region/c_dialog_event_storage_region_module";
import { c_dialog_score_event_viewer_module } from "./score_event_viewer/c_dialog_score_event_viewer_module";
import { c_dialog_share_incident_report_email_module } from "./share-incident-report-email/c_dialog_share_incident_report_email_module";
import { c_dialog_share_incident_report_log_module } from "./share-incident-report-log/c_dialog_share_incident_report_log_module";
import { c_dialog_mp_direction_procedure_module } from "./mp-direction-procedure/c_dialog_mp_direction_procedure_module";
import { c_dialog_mp_direction_copy_paste_module } from "./mp-direction-copy-paste/c_dialog_mp_direction_copy_paste_module";
import { c_dialog_mp_settings_bulk_update_module } from "./mp_settings_bulk_update/c_dialog_mp_settings_bulk_update_module";
import { c_dialog_mp_settings_bulk_update_confirm_module } from "./mp_settings_bulk_update_confirm/c_dialog_mp_settings_bulk_update_confirm_module";

import { c_dialog_warning_module } from "./warning/c_dialog_warning_module";

@NgModule({
  providers: [c_dialog],

  imports: [
    c_dialog_basic_module, 
    c_dialog_dealer_role_module, 
    c_dialog_dealer_division_module, 
    c_dialog_dealer_division_add_sites_module,
    c_dialog_dealer_division_add_users_module,
    c_dialog_dealer_division_clear_module,
    c_dialog_dealer_member_add_user_module,
    c_dialog_dealer_payment_sepa_module,
    c_dialog_event_storage_region_module,
    c_dialog_score_event_viewer_module,
    c_dialog_share_incident_report_email_module,
    c_dialog_share_incident_report_log_module,
    c_dialog_mp_direction_procedure_module,
    c_dialog_mp_direction_copy_paste_module,
    c_dialog_warning_module,
    c_dialog_mp_settings_bulk_update_module,
    c_dialog_mp_settings_bulk_update_confirm_module,
  ],
})
export class c_dialog_module {}

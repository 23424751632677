import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Permission } from '../permission'
import { dealer_plan } from '../dealer_plan'
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class mp_settings {
  constructor(private ims: Imports, private dealer_plan: dealer_plan, private permission: Permission) {}

  public mpSettings$w(): Observable<any> {
    return this.ims.mpSettingsService.mpSettings$w;
  }
  public dealerDefaultMpSettings$w(): Observable<any> {
    return this.ims.mpSettingsService.dealerDefaultMpSettings$w;
  }

  public async get_site_mp_settings(site_id): Promise<Array<any>> | undefined {
    await this.load_site_mp_settings(site_id);
    return this.ims.mpSettingsService.mpSettings$s.getValue();
  }

  public async get_dealer_default_mp_settings(): Promise<Array<any>> | undefined {
    await this.load_dealer_default_mp_settings();
    return this.ims.mpSettingsService.dealerDefaultMpSettings$s.getValue();
  }

  public async update_site_mp_settings(site_id, data: any): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      
      const hasSiteNoteUpdatePermission = this.permission.has("mp_device_setting_update")
      if(!hasSiteNoteUpdatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.mpSettingsService.updateSiteMpSettings(dealer_id, site_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update Site Note` });
      return false;
    }
  }

  public async load_site_mp_settings(site_id): Promise<boolean> {
    try {
      
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const dealerPlanType = await this.dealer_plan.get_dealer_plan()
      if(dealerPlanType?.current_month_service_plan_type < 3) return

      await this.permission.load_my_permissions_only_if_not_loaded()
      
      const hasSiteNoteUpdatePermission = this.permission.has("mp_device_setting_update")
      if(!hasSiteNoteUpdatePermission) return

      await this.ims.mpSettingsService.getSiteMpSettings(dealer_id, site_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Monitoring Settings" });
      return false;
    }
  }

  public async update_dealer_default_mp_settings(data: any): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      
      const hasSiteNoteUpdatePermission = this.permission.has("mp_device_setting_update")
      if(!hasSiteNoteUpdatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.mpSettingsService.updateDealerDefaultMpSettings(dealer_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update Dealer default monitoring settings` });
      return false;
    }
  }

  public async update_dealer_mp_settings_sync(data: any): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      
      const hasSiteNoteUpdatePermission = this.permission.has("mp_device_setting_update")
      if(!hasSiteNoteUpdatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.mpSettingsService.updateDealerMpSettingsSync(dealer_id, data).toPromise();
      return true;
    } catch (err) {
      // tool.dispatch_event("api-failure", { err, title: `Update Dealer default monitoring settings sync` });
      throw false;
    }
  }

  public async load_dealer_default_mp_settings(): Promise<boolean> {
    try {
      
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const dealerPlanType = await this.dealer_plan.get_dealer_plan()
      if(dealerPlanType?.current_month_service_plan_type < 3) return

      await this.permission.load_my_permissions_only_if_not_loaded()
      
      const hasSiteNoteUpdatePermission = this.permission.has("mp_device_setting_update")
      if(!hasSiteNoteUpdatePermission) return

      await this.ims.mpSettingsService.getDealerDefaultMpSettings(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get dealer default monitoring settings" });
      return false;
    }
  }

  // --------------------------------
  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}

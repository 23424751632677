import { Component, HostListener, Input, Output, EventEmitter, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { PagerService } from '@app/services/pager.service';
import { CommonService } from '@app/services/common.service';
import { SitesService } from '@app/services/sites.service';
import { Helper } from '../../../../../../4services/2helper'
import ims from '../../../imports'
import { c_components } from 'src/3ui/2components';

@Component({
  selector: 'c_dtable_monitoring_portal_direction',
  templateUrl: './c_dtable_monitoring_portal_direction.component.pug',
  styleUrls: ['../../../common.scss', '../site-note-manager.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class c_dtable_monitoring_portal_direction_component {
  @Input() pagedItemIsReady: boolean;
  @Input() mpDirectionPagedItems: any;
  @Input() filterList: any;
  @Output() reload = new EventEmitter();
  @ViewChildren('directionActionMenus') directionActionMenus: QueryList<any>
//-----------------------------------------------------------------------------

  isLoading = false;
  isUsedMPBeta = false;
  hasReadDirectionPermission = false;
  hasCreateDirectionPermission = false;
  hasUpdateDirectionPermission = false;
  hasApproveDirectionPermission = false;

  mpDirectionTableHeader = [
    { name: 'SITE ID', value: 'site_id', width: "7%" },
    { name: 'SITE NAME', value: 'site_name', width: "10%" },
    { name: 'TYPE', value: 'type', width: "5%" },
    { name: 'STATUS', value: 'status', width: "10%" },
    { name: 'CH#', value: 'zone_number', width: "5%" },
    { name: 'SITE / CAMERA NAME', value: 'direction_name', width: "22%" },
    { name: 'DEALER APPROVAL', value: 'site_owned_dealer_approved_by', width: "10%" },
    { name: 'STATION APPROVAL', value: 'monitoring_operator_approved_by', width: "10%" },
    { name: 'DATE CREATED', value: 'created_at', width: "8%" },
    { name: 'DATE UPDATED', value: 'updated_at', width: "8%" },
    { name: '', value: null, width: "5%" },
  ]
  pagedItems = [];

  notApprovedCount = 0
  approvalPendingCount = 0
  finalizedCount = 0
  deleteRequested = 0
  noContentCount = 0

  // Pager
  pager: any = {};
  curPage = 1;
  totalItem = 0;
  pageOffset = 50;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;

  constructor(
    public pagerService : PagerService,
    public commonService: CommonService,
    private sitesService: SitesService,
    public c_components: c_components,
    public helper: Helper,
  ) { }

  async ngOnInit() {
    await this.checkSiteOwner()
  }

  ngOnChanges(changes) {
    this.applyItemList(changes)
    this.applyFilter(changes)
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.directionActionMenus) {
      const directionActionMenus = this.directionActionMenus.toArray();
      directionActionMenus.forEach(actionMenu => {
        this.pagedItems.forEach(mpDirection=> {
          if (`direction-${mpDirection.id}` === actionMenu.nativeElement.id) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              mpDirection.isShowMenu = false;
            }
          }
        })
      })
    }
  }

  async checkSiteOwner() {
    const dealerInfo = await this.helper.dealer.get_dealer_info(true);
    this.isUsedMPBeta = !!dealerInfo[0]?.mp_beta_redirect // true : V2 (Beta), false : V1
  }

  applyItemList(changes){
    if(!changes?.pagedItemIsReady) return
    if(changes?.pagedItemIsReady?.currentValue === changes?.pagedItemIsReady?.previousValue) return
    if(!changes?.pagedItemIsReady?.currentValue) return

    this.isLoading = true
    this.checkPermission()
    this.setPage(1)
    this.isLoading = false
  }

  checkPermission(){
    // 여긴 이미 준비되어 있음. change에서 실행되니까
    this.hasReadDirectionPermission = this.helper.permission.has("mp_direction_read")
    this.hasCreateDirectionPermission = this.helper.permission.has("mp_direction_create")
    this.hasUpdateDirectionPermission = this.helper.permission.has("mp_direction_update")
    this.hasApproveDirectionPermission = this.helper.permission.has("mp_direction_approve")
  }

  applyFilter(changes) {
    if(!changes?.filterList) return
    if(changes?.filterList?.currentValue === changes?.filterList?.previousValue) return
    if(!changes?.filterList?.currentValue) return

    this.isLoading = true
    this.setPage(1)
    this.isLoading = false
  }

  // --------------------------------------------------------------------------------
  setMorePosition(id) {
    const docElem = document.documentElement
    let elem = document.getElementById('direction-'+id);
    let more = document.getElementById('more-direction-'+id);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect?.right - 10;
    const posY = rect?.bottom + 7;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(direction){
    const targetMenu = direction.isShowMenu;
    direction.isShowMenu = !targetMenu;
  }

  computedStatusLabelColor(direction): string{
    if(!direction) return ''
    if(this.isNoContents(direction)) return 'no-contents'
    if(direction.status === 0) return 'not-approved-label'
    if(direction.status === 1) return 'pending-label'
    if(direction.status === 2) return 'finalized-label'
    if(direction.status === 3) return 'pending-label'
    if(direction.status === 4) return 'delete-requested-label'
  }

  computedStatusLabelIcon(direction): string {
    if(!direction) return ''
    if(this.isNoContents(direction)) return ''
    if(direction.status === 0) return 'edit'
    if(direction.status === 1) return 'schedule'
    if(direction.status === 2) return 'check_circle'
    if(direction.status === 3) return 'schedule' // 값은 invalid이지만 표시는 pending으로
    if(direction.status === 4) return 'do_not_disturb_on'
  }

  computedMPDirectionStatusLabelColor(value){
    if(value === 'Skip') return true
    if(value === 'Waiting for approval') return true
    return false
  }

  isNoContents(direction){
    return direction.status === null
  }

  isNeedToCheck(value){
    return value === 'Need to Check'
  }

  determineElementToShowInMPDirection(){
    if(this.isLoading) return false
    if(!this.hasReadDirectionPermission) return 'no-permission'
    if(!this.isUsedMPBeta) return 'not-mp-beta'
    if(!this.pagedItems?.length) return 'no-data'
    return 'normal'
  } 

  goToDirectionDetail(event, direction){
    event.stopPropagation();

    try {
      const noteId = direction?.id;
      const url = `/customers/monitoring/direction/${noteId}/monitoring-detail`
      this.sitesService.selSite = this.sitesService.sites?.find(site => site.site_id === direction.site_id)
      this.commonService.emitSiteTab([{targetNoteId: noteId, targetUrl: url, name: 'Directions', link: 'monitoring/direction'}, 'direction'])
      this.helper.router.navigate_to(`/customers/monitoring/direction/${noteId}/monitoring-detail`, {id : direction.site_id});
    } catch(err) {
      console.debug('goToDirectionDetail', err)
    }
  }

  goToAddDirectionDetail(event, direction){
    event.stopPropagation();
    if(!this.hasCreateDirectionPermission) return this.openWarningPermissionDialog('add')

    try {
      const targetId = direction?.id;
      const url = `/customers/devices/note/monitoring-detail/add/${targetId}`
      this.sitesService.selSite = this.sitesService.sites?.find(site => site.site_id === direction.site_id)
      this.commonService.emitSiteTab([{targetNoteId: targetId, targetUrl: url, name: 'Notes', link: 'note'}, 'note'])
      this.helper.router.navigate_to(`/customers/devices/note/monitoring-detail/add/${targetId}`, {id : direction.site_id});
    } catch(err) {
      console.debug('goToAddDirectionDetail', err)
    }
  }

  goToMPBetaSettings(e){
    e?.stopPropagation();
    this.helper.router.navigate_to(`/settings/monitoring-portal-version`);
  }

  checkTotalCountInMPDirection(target){
    this.notApprovedCount = 0
    this.approvalPendingCount = 0
    this.finalizedCount = 0
    this.deleteRequested = 0
    this.noContentCount = 0

    target.forEach(direction => {
      if(direction.status === 0) this.notApprovedCount++
      if(direction.status === 1) this.approvalPendingCount++
      if(direction.status === 2) this.finalizedCount++
      if(direction.status === 3) this.approvalPendingCount++ // 값은 invalid이지만 label은 approval pending으로
      if(direction.status === 4) this.deleteRequested++
      if(direction.status === null) this.noContentCount++
    })
  }

  // -----------------------------------------------------------------------------
  // ACTION
  doWeNeedApproveBtn(direction){
    if(!direction?.id) return false
    if(direction?.status === null) return false
    if(direction?.status === 4) return false // Delete Requested
    if(!this.hasApproveDirectionPermission) return false

    if(direction?.status === 1){
      // Finalize 후에 Monitoring station이 변경되었으면 이 경우도 가능함.
      if(direction.isOwnSite) return true

      // 내가 사이트도 가지고 있고 모니터링도 하고 있으면 바로 2로 넘어감
      if(direction.monitoring_operator_approved_by) {
        // 내가 monitoring 하는 딜러이면 approve 버튼 안보이게
        return direction.isSiteMonitoringDealer // monitoring dealer면 다 표시
      }
      if(direction.site_owned_dealer_approved_by) {
        // 내가 사이트를 가지고 있는 딜러이면 approve 버튼 안보이게
        return direction.isSiteOwnerDealer ? false : true
      }
    }

    if(direction?.status === 2) return false
    return true
  }

  async onClickApprove(event, direction){
    event.stopPropagation();
    this.isLoading = true;
    
    let status 
    if(direction.isOwnSite || direction.isSiteMonitoringDealer) {
      status = 2
    } else {
      direction .status === 1 ? status = 2 : status = 1
    }
    const result = await this.editMonitoringNoteStatus(direction, status) 
    if(result){
      await ims.tool.sleep(500)
      this.reload.emit();
      this.commonService.showSuccessToast('Success','Monitoring direction updated successfully.')
    } else {
      this.commonService.showErrorToast('Error','Invalid status')
    }
    direction.isShowMenu = false;
    this.isLoading = false;
  }
  async editMonitoringNoteStatus(direction, status): Promise<boolean>{
    try {
      if(!direction?.id) return false
      const data = { status }
      await this.helper.note_directions.update_monitoring_direction_status(direction.site_id, direction.id, data)
      return true
    } catch(err) {
      this.isLoading = false;
      console.debug('editMonitoringNoteStatus:>',err)
      return false
    }
  }

  // -----------------------------------------------------------------------------
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openWarningPermissionDialog(permission: string){
    let contents = ''
    if(permission === 'add') {
      contents = `<p>You do not have add permissions to access this.</b></p>`
    } else if(permission === 'edit') {
      contents = `<p>You do not have edit permissions to access this.</b></p>`
    }

    this.c_components.dialog.open("warning", {
      header: 'Access Denied',
      contents: contents,
      submit_btn: "OK",
      submit_class: ["button-danger"],
      icon: 'do_not_disturb_on',
      isConfirm: true,
      color: 'orange',
      submit_func: () => {},
    });
  }

  sortTableByColumnInMPDirections(sortTarget) {
    if (!sortTarget.sortColumn || sortTarget.sortDirection === '') {
      return;
    }
    let data = this.mpDirectionPagedItems;

    const tmpData = data.sort((a, b) => {
      const isAsc = sortTarget.sortDirection === 'asc';
      switch (sortTarget.sortColumn) {
        case 'site_id': 
          return this.compare(a.site_id, b.site_id, isAsc);
        case 'site_name': 
          return this.compare(a.site_name, b.site_name, isAsc);
        case 'type': 
          return this.compare(a.type, b.type, isAsc);
        case 'status': 
          return this.compare(a.status, b.status, isAsc);
        case 'zone_number': 
          return this.compare(a.zone_number, b.zone_number, isAsc);
        case 'direction_name': 
          return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
        case 'site_owned_dealer_approved_by': 
          return this.compare(a.site_owned_dealer_approved_by.toLowerCase(), b.site_owned_dealer_approved_by.toLowerCase(), isAsc);
        case 'monitoring_operator_approved_by': 
          return this.compare(a.monitoring_operator_approved_by.toLowerCase(), b.monitoring_operator_approved_by.toLowerCase(), isAsc);
        case 'created_at': 
          return this.compare(a.created_at, b.created_at, isAsc);
        case 'updated_at': 
          return this.compare(a.updated_at, b.updated_at, isAsc);
        default: return 0;
      }
    });
    
    this.mpDirectionPagedItems = tmpData;
    this.setPage(1)
  }

  setPage(page: number) {
    this.pagedItems = []

    const target = this.filterItems()
    this.checkTotalCountInMPDirection(target)
    if(!target){
      console.debug('⚠️ list is lost', target)
      return this.isLoading = false
    }
    
    // get pager object from service
    this.pager = this.pagerService.getPager(target?.length, page, this.pageOffset);
    this.totalItem = target?.length;
    
    // get current page of items
    this.pagedItems = target.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    if (this.curPage -1 < 0) {
      this.pageOffsetStart = 0;
    } else if (this.curPage -1 == 0) {
      this.pageOffsetStart = 1;
    } else {
      this.pageOffsetStart =  (this.curPage -1) * this.pageOffset +1;
    }

    if (this.curPage * this.pageOffset > this.totalItem) {
      this.pageOffsetEnd =  this.totalItem;
    } else {
      this.pageOffsetEnd =  this.curPage * this.pageOffset;
    }
  }

  filterItems() {
    if(!this.filterList) return

    // 1. 선택된 site
    const selectedSite = this.filterList?.selectedSite

    // 2. 선택된 status 리스트
    const selectedStatuses = this.filterList?.directionStatusList

    // 3. 필터링된 아이템
    const target = ims._.cloneDeep(this.mpDirectionPagedItems)
    return target.filter(item => {
      // site 필터
      const siteMatch = !selectedSite || selectedSite.site_id === item.site_id

      // status 필터
      const statusMatch = selectedStatuses.length === 0 || selectedStatuses.includes(item.statusLabel) || (selectedStatuses.includes('None : No Content') && item.status === null);

      // createdAt 필터
      const createdAtMatch = (!this.filterList?.createdAtRange.start || new Date(item.created_at) >= new Date(this.filterList?.createdAtRange.start)) &&
                             (!this.filterList?.createdAtRange.end || new Date(item.created_at) <= new Date(this.filterList?.createdAtRange.end));
  
      // updatedAt 필터
      const updatedAtMatch = (!this.filterList?.updatedAtRange.start || new Date(item.updated_at) >= new Date(this.filterList?.updatedAtRange.start)) &&
                             (!this.filterList?.updatedAtRange.end || new Date(item.updated_at) <= new Date(this.filterList?.updatedAtRange.end));
  
      return siteMatch && statusMatch && createdAtMatch && updatedAtMatch;
    });
  }
}
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UsersService } from './services/users.service';
import { c_components } from '../3ui/2components'

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(
    private router: Router, 
    private usersService: UsersService, 
    private c_components: c_components,
  ) {}

  openDialogAlertLimitedAccess() {
    this.c_components.dialog.open("warning", {
      header: 'Limited Access - "Soft Block"',
      contents: `
        <p>
        Notice: Due to a past-due status on your account, access to your CHeKT Dealer Portal has been limited to the payment and invoice section. Please process payment on all open invoices and then contact us at support@chekt.com to request full access to your account.
        <br/><br/>
        Important Note:  This "soft block" status does not impact the services of your customer sites. If the past due status is not resolved within 30 days, your account will be moved to a "Service Interruption" status, and all CHeKT services will be blocked for each customer account. 
        <br/><br/>
        Thank you for your attention to this matter.
        </p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: "warning",
      isConfirm: true,
      color: "orange",
      submit_func: () => {
        return
      },
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    
    const me = this.usersService.me
    // A라는 flag의 상태를 검사합니다. 
    if (
      me?.is_activated &&
      (!me?.app_access_permissions?.has_access_dealer_app && me?.app_access_permissions?.has_access_payment_page)
    ) {
      if ((state.url != '/settings/payment-preferences' && state.url != '/settings/invoices') && state.url != '/settings/retail-statistics') {
        this.openDialogAlertLimitedAccess()

        if(me.type === 3) {
          this.router.navigate(['/settings/retail-statistics']);
        } else {
          this.router.navigate(['/settings/payment-preferences']);
        }
        return false;
      }
      return true;
    }
    // 기존 로직에 따라 작동
    return true;
  }
}

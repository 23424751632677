import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class sites {
  constructor(private ims: Imports) {}

  public init_sites(): void {
    this.ims.sitesService.sites = null;
    this.ims.sitesService.sites$s.next(null);
    return
  }

  public sites_loaded(): Array<any> {
    return this.ims.sitesService.sites;
  }

  public site$w(): Observable<any> {
    return this.ims.sitesService.site$w;
  }

  public sites$w(): Observable<any> {
    return this.ims.sitesService.sites$w;
  }

  public searchedSites$w(): Observable<any> {
    return this.ims.sitesService.searchedSites$w;
  }

  public division_sites$w(): Observable<any> {
    return this.ims.sitesService.divisionSites$w;
  }

  public async get_site(siteId): Promise<Array<any>> | undefined {
    await this.load_site(siteId);
    return this.ims.sitesService.site$s.getValue();
  }

  public async get_sites( partnerId?, fields?): Promise<Array<any>> | undefined {
    await this.load_sites( partnerId, fields);
    return this.ims.sitesService.sites$s.getValue();
  }

  public async get_searched_sites(search_text): Promise<Array<any>> | undefined {
    await this.load_searched_sites(search_text);
    return this.ims.sitesService.searchedSites$s.getValue();
  }

  public async get_division_sites(divisionId): Promise<Array<any>> | undefined {
    await this.load_division_sites(divisionId);
    return this.ims.sitesService.sites$s.getValue();
  }

  public async load_site(siteId): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.sitesService.getSite(dealer_id, siteId).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Site" });
      return false;
    }
  }

  public async load_sites(partnerId?, fields?): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.sitesService.fetch(dealer_id,  partnerId, fields).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Sites" });
      return false;
    }
  }

  public async load_searched_sites(search_text): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.sitesService.siteSearch(dealer_id, search_text).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Sites" });
      return false;
    }
  }
  
  public async load_division_sites(divisionId): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.sitesService.getDivisionSites(dealer_id, divisionId).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Division Sites" });
      return false;
    }
  }

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
